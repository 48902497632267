import mmcAjax from "@/common/js/mmcAjax"
export default {


    getProj(projuuid) {
        let url = `${window.APIBasePath}mmc/project/info/${projuuid}`

        return mmcAjax({
            method: 'get',
            url,
        })
    }
}