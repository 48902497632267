<template>
    <!-- <div> -->
        <div :class="['qsc-btn-nomal', type]" v-on="$listeners">
            <slot>{{ text }}</slot>
        </div>
    <!-- </div> -->
</template>

<script>
export default {
    name: 'qsc-btn-nomal',
    props: {
        text: {
            type: String,
            default: '设定文字',
        },
        type: {
            type: String,
            default: 'solid',
        },
    },
    data() {
        return {};
    },
    components: {},
};
</script>

<style lang="less" scoped>
.qsc-btn-nomal {
    display: block;
    font-size: 17px;
    line-height: 1em;
    padding: 11px;
    min-width: 64px;
    text-align: center;

    background-color: #fff;
    border-radius: 4px;
    width: 70%;
    margin: 0 auto;
    border: 0;
    // font-weight: 500;
}
.solid {
    color: #fff;
    background-image: linear-gradient(90deg, #40b9ff 0%, #009bff 100%);
    box-shadow: 0px 4px 30px 0px rgba(0, 155, 255, 0.4);
}
.hollow {
    color: #333;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
}
</style>