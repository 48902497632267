<template>
    <div>
        <div v-if="audit_state == 1" class="pay-success-main">
            <div class="love-pay-success-header">
                <div class="dialog-box">
                    <i class="icon"></i>
                    项目{{ typeString }}成功<br />
                    请您耐心等待审核结果
                </div>
            </div>
        </div>
        <div v-if="audit_state == 2" class="pay-success-main">
            <div class="love-pay-success-header">
                <div class="dialog-box">
                    <i class="icon error"></i>
                    项目审核不通过<br />
                    请您修改后重新提交
                </div>
            </div>

            <mmcBtnNormal class="mt20" @click="openShareModal"
                >修改项目</mmcBtnNormal
            >
        </div>

        <div
            class="modal-share"
            v-show="shareModalDisplay"
            @click="closeShareModal"
        >
            <div class="modal-content">
                <div class="text-box">
                    <h1>点击右上角 <i class="mainIcon"></i></h1>
                    <p><i class="shareIcon"></i>分享到微信好友</p>
                    <p><i class="moments"></i>分享到朋友圈</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mmcBtnNormal from '@/components/mmcUI/mmc-btn-normal.vue';
import { utils, shareProj, authChecker } from '@/common/js/mmcModule';
import api from './api';
let requestParams = {};

export default {
    data() {
        return {
            shareModalDisplay: false,
            typeString: '',
            audit_state: -1,
        };
    },
    components: {
        mmcBtnNormal,
    },
    methods: {
        openShareModal() {
            // this.shareModalDisplay = true;
            this.$router.push({
                path: '/fund/publish/edit',
                query: { projuuid: requestParams.projuuid },
            });
        },
        closeShareModal() {
            this.shareModalDisplay = false;
        },
        viewProjDetail() {
            this.$router.push(
                '/fund/project/detail?projuuid=' + requestParams.projuuid
            );
        },
    },
    mounted() {
        requestParams = utils.getRequestParams();
        let that = this;
        if (requestParams.type == 'edit') {
            that.typeString = '编辑';
        } else {
            that.typeString = '发布';
        }
        authChecker.check({ notneedphone: 0 }, res => {
            api.getProj(requestParams.projuuid).then(res => {
                if (res.code == 0) {
                    this.audit_state = res.data.audit_state;
                    // this.audit_state = 2;
                    if (this.audit_state == 3) {
                        location.replace(
                            '/fund/project/detail?projuuid=' +
                                requestParams.projuuid
                        );
                    }
                }
            });
            shareProj.init({
                projuuid: requestParams.projuuid,
                success() {
                    that.shareModalDisplay = false;
                },
            });
        });
    },
};
</script>

<style lang="less" scope>
.pay-success-main {
    a {
        cursor: pointer;
    }
    padding-top: 30px;
    .dialog-box {
        font-size: 17px;
        color: #333333;

        margin: 20px 0 100px;
        line-height: 27px;
        .icon {
            width: 200px;
            height: 200px;
            display: block;
            background: url('./img/publishSuccess.png') no-repeat;
            background-size: contain;
            margin: auto;
        }
        .icon.error {
            width: 200px;
            height: 200px;
            display: block;
            background: url('./img/icon-ok-grey.png') no-repeat;
            background-size: contain;
            margin: auto;
        }
    }
}
.modal-share {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1 !important;
    background-color: rgba(0, 0, 0, 0.5);

    .modal-content {
        border-radius: 0;
        width: 285px;
        height: 158px;

        margin: auto;
        margin-right: 20px;
        margin-top: 20px;
        background: url('./img/share-bg.png') no-repeat;
        background-size: cover;
        padding: 26px 0 25px 70px;
        .text-box {
            text-align: left;
            h1 {
                font-size: 25px;
                color: #ffffff;
                margin: 0 0 12px;
                position: relative;
            }
            .mainIcon {
                width: 22px;
                height: 5px;
                display: inline-block;
                background: url('./img/icon.png') no-repeat;
                background-size: contain;
                position: relative;
                top: -6px;
                left: 4px;
            }

            p {
                font-size: 16px;
                color: #ffffff;
                margin: 0;
            }
            p:last-of-type {
                margin: 5px 0 0px;
            }
            .shareIcon {
                width: 18px;
                height: 18px;
                display: inline-block;
                background: url('./img/shareIcon.png') no-repeat;
                background-size: contain;
                margin: 0 10px 0 0;
            }
            .moments {
                width: 18px;
                height: 18px;
                display: inline-block;
                background: url('./img/Moments.png') no-repeat;
                background-size: contain;
                margin: 0 10px 0 0;
            }
        }
    }
}
.mt20 {
    margin-top: 20px !important;

    width: 200px !important;
}
</style>
